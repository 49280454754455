import GATSBY_COMPILED_MDX from "/opt/build/repo/content/blog/what-actually-happens-in-a-cbt-appointment-1.md";
import React from "react";
import PropTypes from "prop-types";
import {graphql} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import Newslettersignup from "../components/Newslettersignup";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";
import Share from "../components/blog/share";
import BlogPagePostRow from "../components/blog/blogPagePostRow";
import BlogSections from "../components/blog/BlogSections";
import TagPreview from "../components/tagWidget/TagPreview";
import {BrandColours} from "../utils/colours";
const TempPageWrapper = (styled.div)`
  max-width: 1000px;
  margin: 0 auto;

  .BlogImage {
    width: 100%;
    max-width: 650px;
    margin: 20px auto;
    display: block;
    border-radius: 20px;
    @media (max-width: 650px){
      width: 87%;
    }
  }
  .DescriptText{
    font-weight: 700;
    font-size: 1.5rem;
    color: ${BrandColours.green.base};
  }
  
  @media (max-width: 900px){
    .DescriptText{
      font-size: 1rem;
    }
  }
  
`;
const TextWrapper = (styled.div)`
  
.body{
  .adsbygoogle {
    display: block;
    text-align:center;
  }
}
h1 {
  font-weight: 700;
  color: ${BrandColours.green.base};
}

a {
  color: ${BrandColours.green.base};
}

p,li {
  font-weight: 600;
}
  .GoogleAdsLeaderboard {
    height: 90px;
    width: 728px;
    margin: auto;
    margin-bottom: 16px;
    @media (max-width: 728px){
      height: 100px;
      width: 320px;
    }
  }

  width: 100%;
  margin: 16px auto;


  &.socials {
    margin: 46px auto 0 0;
  }
  .TitleDivide{
    width: 100%;
    height: 20px;
    background-color: ${BrandColours.purple.base};
    border-radius: 8px;  
  }
`;
const TopMisc = (styled.div)`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  color: ${BrandColours.green.base};
  align-items: flex-start;
`;
const GoogleAds = (styled.div)`
.GoogleRow{
  display: flex;
  justify-content: center;
  align-items: center;
    max-height: 95px;
    max-width: 100%;
    min-width: 300px;
    min-height: 50px;

    .GoogleAdsLeaderboard {
      height: 90px;
      width: 728px;
      margin-bottom: 16px;
      @media (max-width: 728px){
        height: 100px;
        width: 320px;
      }
    }
}`;
const BlogPostTemplate = ({data, children}) => {
  const post = data.mdx;
  const heroImage = getImage(post.frontmatter.imageUrl);
  const siteTitle = data.site.siteMetadata.title;
  const twitterHandle = data.site.siteMetadata.social.twitter;
  const siteUrl = data.site.siteMetadata.siteUrl;
  const slug = data.mdx.fields.slug;
  const relatedPosts = data.allMdx.edges;
  const sectionList = data.mdx.frontmatter.sections;
  const tags = data.mdx.frontmatter.tags || [];
  return React.createElement(Layout, {
    location: data.location,
    title: siteTitle
  }, React.createElement(SEO, {
    title: post.frontmatter.title,
    description: post.frontmatter.description || post.excerpt
  }), React.createElement(TempPageWrapper, null, React.createElement(TextWrapper, null, React.createElement("h1", null, post.frontmatter.title), React.createElement("div", {
    className: "TitleDivide"
  }, " ")), React.createElement(GatsbyImage, {
    className: "BlogImage",
    image: heroImage,
    alt: post.frontmatter.description
  }), React.createElement("p", {
    className: "DescriptText"
  }, post.frontmatter.author, " • ", post.frontmatter.date, " • ", post.frontmatter.readTime), React.createElement(TopMisc, null, React.createElement(Share, {
    socialConfig: {
      twitterHandle,
      config: {
        url: `${siteUrl}/blog/${slug}`,
        title: siteTitle,
        imageUrl: `${siteUrl}/${post.frontmatter.imageUrl.publicURL}`
      }
    }
  })), React.createElement(TextWrapper, {
    className: "body"
  }, children, post.frontmatter.hasSections === true && React.createElement(BlogSections, {
    sections: sectionList
  }), React.createElement(TagPreview, {
    value: tags
  })), React.createElement(TopMisc, null, React.createElement(Share, {
    socialConfig: {
      twitterHandle,
      config: {
        url: `${siteUrl}/${slug}`,
        title: siteTitle,
        imageUrl: `${siteUrl}/${post.frontmatter.imageUrl.publicURL}`
      }
    },
    tags: tags
  })), React.createElement(BlogPagePostRow, {
    posts: relatedPosts,
    tags: tags,
    slug: slug
  }), React.createElement(Newslettersignup)));
};
BlogPostTemplate.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired
  }),
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape({
          frontmatter: PropTypes.shape({
            title: PropTypes.string.isRequired
          }),
          fields: PropTypes.shape({
            slug: PropTypes.string.isRequired
          })
        })
      }).isRequired)
    })
  })
};
BlogPostTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPostTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query ($id: String,
    ) {
    site {
      siteMetadata {
        title
        author
        siteUrl
        social { twitter }
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      fields {
        slug
      }
      frontmatter {
        title
        date: date(formatString: "Do MMMM YYYY")
        description
        author
        readTime
        tags
        imageUrl { 
          childImageSharp {
            gatsbyImageData(
              webpOptions: {quality: 70}
              layout: FULL_WIDTH
              blurredOptions: {toFormat: WEBP, width: 10}
              breakpoints: 1920
            )
          }
          publicURL
        }
        sections {
          sectionTitle
          sectionDescription
          body
          type
          sectionImageUrl {
            childImageSharp {
              gatsbyImageData(
                webpOptions: {quality: 70}
                layout: FULL_WIDTH
                blurredOptions: {toFormat: WEBP, width: 10}
                breakpoints: 1920
              )
            }
          }
        }
        hasSections
      }
    }
   allMdx(
      filter: {internal: {contentFilePath: {regex: "//blog/"}}}
      sort: {fields: frontmatter___tags, order: DESC}
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "Do MMMM YYYY")
            title
            description
            imageUrl {
              childImageSharp {
                gatsbyImageData(
                  webpOptions: {quality: 70}
                  layout: FULL_WIDTH
                  blurredOptions: {toFormat: WEBP, width: 10}
                  breakpoints: 1920
                )
              }
              publicURL }
            contentImageUrl { 
              childImageSharp {
                gatsbyImageData(
                  webpOptions: {quality: 70}
                  layout: FULL_WIDTH
                  blurredOptions: {toFormat: WEBP, width: 10}
                  breakpoints: 1920
                )
              }
            }
            author
            readTime
            tags
            isFeatured
          }
        }
      }
    }
  }
`;
